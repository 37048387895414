<template>
  <div class="create-event">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner create-event-inner">
        <trail
          :steps="steps"
          :canSubmit="canSubmit"
          @change="next"
          :isVirtual="checkIsVirtual"
          :isGuestList="checkIsGuestList"
        />
        <div class="page">
          <Card>
            <transition mode="out-in" name="fade">
              <router-view
                is-create-event
                :info="values"
                :errors="errors"
                @data="data"
              />
            </transition>
            <div class="progress-counter">
              <p>Step {{ currentStep.number }} of {{ steps.length }}</p>
            </div>
            <div class="button-wrapper">
              <FormulateInput
                v-if="currentStep.number > 1"
                type="button"
                name="← Back"
                @click="back"
              />

              <FormulateInput
                v-if="currentStep.number >= steps.length"
                type="button"
                name="Submit"
                :disabled="errors.submit"
                @click="send"
              />
              <FormulateInput
                v-else
                type="button"
                name="Next →"
                @click="next"
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import Trail from "../components/cec/Trail.vue";
import dayjs from "dayjs";
import { whiteLabelRedirection } from "@/helpers/whiteLabel";

export default {
  name: "create-event",
  components: {
    DiyoboInput,
    Trail
  },
  head() {
    return {
      title: "Create Event"
    };
  },
  data() {
    return {
      users: [],
      user: null,
      steps: [
        {
          number: 1,
          name: "Config",
          link: "config",
          state: "unchanged"
        },
        {
          number: 2,
          name: "Date & Times",
          link: "dates",
          state: "disabled"
        },
        {
          number: 3,
          name: "Venue",
          link: "venue",
          state: "disabled"
        },
        {
          number: 4,
          name: "Images",
          link: "images",
          state: "disabled"
        },
        {
          number: 5,
          name: "Details",
          link: "details",
          state: "disabled"
        },
        {
          number: 6,
          name: "Tickets",
          link: "tiers",
          state: "disabled"
        },
        {
          number: 7,
          name: "Products",
          link: "products",
          state: "disabled"
        },
        {
          number: 8,
          name: "Planner Terms",
          link: "terms",
          state: "disabled"
        },
        {
          number: 9,
          name: "Submit",
          link: "submit",
          state: "disabled"
        }
      ],
      values: {
        config: {
          tags: [],
          taxes: []
        },
        dates: {
          dates: []
        },
        venue: {
          coordinates: {}
        },
        images: {},
        details: {},
        tiers: {
          ticketTiers: [{ id: 0, dfee: 2.0, ccfee: 2.0 }],
          addedTiers: []
        },
        products: {
          products: []
        },
        terms: {},
        submit: {},

        brand: {}
      },
      // if true, the form will have all the error messages displayed once they navigate to the page again
      errors: {
        config: false,
        dates: false,
        venue: false,
        images: false,
        details: false,
        tiers: false,
        products: false,
        terms: false,
        submit: false
      }
    };
  },
  computed: {
    checkIsVirtual() {
      return this.values.config.eventType === "VIRTUAL";
    },
    checkIsGuestList() {
      return this.values.config.ticketsOrGuestList === "guestList";
    },
    activeBrand() {
      return this.$store.state.activeBrand;
    },
    currentRoute() {
      return this.$route.path.split("/").pop();
    },
    currentStep() {
      return this.steps.find(s => s.link === this.currentRoute);
    },
    canSubmit() {
      return this.steps.slice(0, -1).every(s => s.state === "done");
    },
    usersForDropdown() {
      return this.users.map(u => ({
        value: u.id,
        label: `${u.name} (${u.email})`
      }));
    }
  },
  methods: {
    next(next) {
      const index = this.steps.indexOf(this.currentStep);
      const currentStep = this.currentStep;
      let nextStep =
        typeof next === "string"
          ? this.steps.find(s => s.link === next)
          : this.steps[index + 1];

      console.log(index, currentStep, nextStep);

      //skip steps if user choose virtual event
      if (
        nextStep.name === "Venue" &&
        this.values.config.eventType === "VIRTUAL"
      ) {
        nextStep = this.steps[index + 2];
      }

      if (
        nextStep.name === "Tickets" &&
        this.values.config.ticketsOrGuestList === "guestList"
      ) {
        nextStep = this.steps[index + 2];
      }

      if (this.canSubmit === false) {
        if (currentStep.link === "config") {
          this.errors.config = true;
        } else if (currentStep.link === "dates") {
          this.errors.dates = true;
        } else if (currentStep.link === "venue") {
          this.errors.venue = true;
        } else if (currentStep.link === "images") {
          this.errors.images = true;
        } else if (currentStep.link === "details") {
          this.errors.details = true;
        } else if (currentStep.link === "tiers") {
          this.errors.tiers = true;
        } else if (currentStep.link === "products") {
          this.errors.products = true;
        } else if (currentStep.link === "terms") {
          this.errors.terms = true;
        }
      }

      if (!nextStep || nextStep.state === "disabled") {
        return;
      } else if (
        nextStep.name !== "submit" ||
        this.canSubmit ||
        currentStep.name === "Config"
      ) {
        if (this.currentRoute !== nextStep.link) {
          this.$router.push("/create-event/" + nextStep.link);
        }
      }
    },
    back() {
      const index = this.steps.indexOf(this.currentStep);
      if (index > -1) {
        let previousStep = this.steps[index - 1];

        //skip steps if user choose virtual event
        if (
          previousStep.name === "Venue" &&
          this.values.config.eventType === "VIRTUAL"
        ) {
          previousStep = this.steps[index - 2];
        }

        if (
          previousStep.name === "Tickets" &&
          this.values.config.eventType === "guestList"
        ) {
          previousStep = this.steps[index - 2];
        }

        if (previousStep) {
          this.$router.push("/create-event/" + previousStep.link);
        }
      }
    },
    data(key, value, error) {
      const validated = typeof error === "boolean";

      this.values[key] = value;

      if (validated) {
        this.errors[key] = error;

        const step = this.steps.find(s => s.link === key);
        if (step) {
          step.state = error ? "error" : "done";
        }
        if (key === "config" && !error) {
          this.steps
            .filter(
              s =>
                ((s.number > 1 && s.number < 6) ||
                  s.number === 8 ||
                  s.number === 9) &&
                s.state === "disabled"
            )
            .forEach(s => {
              s.state = "unchanged";
            });
        }

        if (this.values.config.eventType === "VIRTUAL") {
          this.steps
            .filter(x => x.number === 3)
            .forEach(y => {
              y.state = "done";
            });
        }

        if (this.values.config.ticketsOrGuestList === "guestList") {
          this.steps
            .filter(s => s.number === 6)
            .forEach(s => {
              s.state = "done";
            });
        }

        if (key === "dates" && !error) {
          this.validateTierDates();

          const datesStep = this.steps.find(s => s.link === "dates");

          if (datesStep && datesStep.state === "done") {
            const tiersStep = this.steps.find(s => s.link === "tiers");
            const productsStep = this.steps.find(s => s.link === "products");
            if (
              tiersStep &&
              tiersStep.state !== "done" &&
              tiersStep.state !== "error"
            ) {
              tiersStep.state = "unchanged";
            }

            if (
              productsStep &&
              productsStep.state !== "done" &&
              productsStep.state !== "error"
            ) {
              productsStep.state = "unchanged";
            }
          }
        }
      }

      if (this.canSubmit) {
        this.steps.find(s => s.link === "submit").state = this.errors.submit
          ? "error"
          : "unchanged";
      }
    },
    validateTierDates() {
      if (this.values.tiers.addedTiers.length === 0) {
        return;
      }

      const start = dayjs(this.values.dates.dates[0].startDate);
      const end = dayjs(this.values.dates.dates[0].endDate);

      for (const tier of this.values.tiers.addedTiers) {
        if (dayjs(tier.venueAccessStart).isBefore(start)) {
          tier.venueAccessStart = start.toISOString();
        }

        if (dayjs(tier.venueAccessEnd).isAfter(end)) {
          tier.venueAccessEnd = end.toISOString();
        }

        for (const saleDate of tier.saleDates) {
          if (dayjs(saleDate.startDate).isBefore(start)) {
            saleDate.startDate = start.toISOString();
          }

          if (dayjs(saleDate.endDate).isAfter(end)) {
            saleDate.endDate = end.toISOString();
          }
        }
      }
    },
    removeSpans(val) {
      let textToSend = String(val);
      let regex = /(<span><\/span>)+/;
      let returnText = textToSend.replace(regex, "");
      return returnText;
    },
    isEventVenueVirtual() {
      if (this.values.config.eventType === "VIRTUAL") {
        this.values.venue = {
          secretVenue: false,
          image: "",
          venueName: "",
          venueAddress: "",
          cityName: "",
          regionName: "",
          regionCode: "",
          zipCode: "",
          contactNumber: "",
          closestCityName: "",
          coordinates: {}
        };
      }
    },
    async send() {
      this.$store.state.creating = true;
      let lastIndexDate = this.values.dates.dates.length - 1;
      const start = this.values.dates.dates[0].startDate;
      const end = this.values.dates.dates[lastIndexDate].endDate;

      const finalTiers = [];
      const tiers = this.values.tiers.addedTiers;

      this.isEventVenueVirtual();

      if (this.values.config.funnel_id.length > 0) {
        this.values.config.funnel_id = this.values.config.funnel_id.map(f =>
          parseInt(f.id)
        );
      }

      for (const tax of this.values.config.taxes) {
        tax.percentage = Number(tax.percentage);
      }

      for (let x = 0; x < tiers.length; x++) {
        let obj = {};

        let imageName =
          tiers[x].image && tiers[x].image.imageName
            ? tiers[x].image.imageName
            : "";

        obj.name = tiers[x].name;
        obj.description = tiers[x].description;
        obj.currencyType = tiers[x].currencyType;
        obj.price = tiers[x].price;
        obj.image = { imageName: imageName };
        obj.ccfee = tiers[x].ccfee;
        obj.dfee = tiers[x].dfee;
        obj.addfee = tiers[x].addfee;
        obj.absorb = tiers[x].absorb;
        obj.type = tiers[x].type;
        obj.ticketType = tiers[x].ticketType;
        obj.tixStartNum = parseInt(tiers[x].tixStartNum);
        obj.totalTicketQuantity = tiers[x].totalTicketQuantity;
        obj.categoryKey = tiers[x].categoryKey;
        obj.venueAccessDates = tiers[x].venueAccessDates;
        obj.venueAccess = {
          start: tiers[x].venueAccessStart,
          end: tiers[x].venueAccessEnd
        };
        obj.saleDates = tiers[x].saleDates.map(element => {
          return {
            sale_start: element.startDate,
            sale_end: element.endDate,
            qty: element.qty,
            start: 1,
            expenses: 0,
            returnedQuantity: 0,
            orderStartNum: element.order,
            soldout: element.soldout,
            purchase_limit: element.purchase_limit,
            show_check_out_add_on: element.show_check_out_add_on,
            do_not_show_in_event: element.do_not_show_in_event,
            min_purchase: parseInt(element.min_purchase),
            max_purchase: parseInt(element.max_purchase)
          };
        });

        if (tiers[x].password && tiers[x].password.length > 0) {
          obj.password = tiers[x].password;
        } else {
          obj.password = false;
        }

        if (tiers[x].restrictEmails && tiers[x].restrictEmailsArray) {
          obj.restrictEmailsArray = tiers[x].restrictEmailsArray;
          obj.restrictEmails = tiers[x].restrictEmails;
        }

        if (tiers[x].tag) {
          obj.tag = tiers[x].tag;
        }
        if (tiers[x].attendee_tag_id) {
          obj.attendee_tag_id = tiers[x].attendee_tag_id;
        }
        if (tiers[x].attendee_removed_tag_id) {
          obj.attendee_removed_tag_id = tiers[x].attendee_removed_tag_id;
        }

        finalTiers.push(obj);
      }

      let finalItineraries = [];
      let finalFields = [];
      let finalExcludedDates = [];

      let finalDescription = this.removeSpans(this.values.details.description);

      const products = this.values.products.products.map(m => {
        return { ...m, image: undefined };
      });

      let objToSend = {
        user: this.user,
        name: this.values.config.name,
        ticketsOrGuestList: this.values.config.ticketsOrGuestList,
        buyerOrPlannerPaysFees: this.values.config.buyerOrPlannerPaysFees,
        collectAttendeeInfo: this.values.config.collectAttendeeInfo,
        pixelId: this.values.config.pixelID,
        obv_link: this.values.config.obv_link,
        sw_link: this.values.config.sw_link,
        tagline: this.values.config.tagline,
        venue: this.values.venue.venueName,
        start: start, // TODO
        end: end, // TODO
        enable_pre_sale: this.values.dates.enable_pre_sale,
        hide_event_dates: this.values.dates.hide_event_dates,
        replay: this.values.dates.replay,
        eventType: this.values.config.eventType,
        seatsEventKey: this.values.config.seatsEventKey,
        timezone: this.values.config.timezone,
        taxes: this.values.config.taxes,
        frequency: this.values.dates.frequency,
        dates: this.values.dates.dates,
        dateSettings: {
          days: this.values.dates.days,
          weeks: this.values.dates.weeks,
          startDate: this.values.dates.startDate,
          startTime: this.values.dates.startTime,
          endDate: this.values.dates.endDate,
          endTime: this.values.dates.endTime,
          endOffset: this.values.dates.endOffset,
          monthlyType: this.values.dates.monthlyType,
          monthlyDay: this.values.dates.monthlyDay
        },
        city: this.values.venue.cityName,
        closestCity: this.values.venue.closestCityName,
        contactNumber: this.values.venue.contactNumber,
        region_name: this.values.venue.regionName,
        region_code: this.values.venue.regionCode,
        country_name: this.values.config.country,
        currency: this.values.config.currency,
        languages: this.values.config.languages,
        email: this.$store.state.user.email, // TODO
        active: true, // TODO
        socialDiscount: this.values.tiers.socialDiscount,
        guestlistOnly: this.values.tiers.isGuestListOnly,
        tiers: finalTiers, // TODO
        archived: false, // TODO
        featured: false, // TODO
        excludedDates: finalExcludedDates, // TODO
        venueAddress: this.values.venue.venueAddress,
        description: finalDescription,
        zipCode: this.values.venue.zipCode,
        hide: this.values.submit.hide,
        hide_in_brand: this.values.submit.hide_in_brand,
        proofread: this.values.submit.proofread,
        hideFees: this.values.submit.hideFees,
        agree: this.values.submit.agree,
        itineraries: finalItineraries, // TODO
        videoLink: this.values.details.videoLink,
        website: this.values.details.website,
        sponsor: this.values.details.sponsor,
        sponsorLink: this.values.details.sponsorLink,
        addedFields: finalFields, // TODO

        privateVenue: this.values.venue.secretVenue,
        primaryCategory: this.values.config.primaryCategory,
        secondaryCategory: this.values.config.secondaryCategory,
        products,
        tags: this.values.config.tags,
        refundPolicy: this.values.terms.refundPolicy,
        privacyPolicy: this.values.terms.privacyPolicy,
        termsOfUse: this.values.terms.termsOfUse,
        coordinates: this.values.venue.coordinates,

        // CF Values
        cf_event_url: this.values.config.cf_event_url,
        funnel_id: this.values.config.funnel_id,

        // Keap Values
        keap_language_custom_field_id: this.values.config
          .keap_language_custom_field_id
          ? parseInt(this.values.config.keap_language_custom_field_id)
          : null,
        keap_company_custom_field_id: this.values.config
          .keap_company_custom_field_id
          ? parseInt(this.values.config.keap_company_custom_field_id)
          : null,
        keap_magic_link_custom_field_id: this.values.config
          .keap_magic_link_custom_field_id
          ? parseInt(this.values.config.keap_magic_link_custom_field_id)
          : null,
        keap_holder_tag_id: this.values.config.keap_holder_tag_id
          ? parseInt(this.values.config.keap_holder_tag_id)
          : null,

        type: this.values.tiers.type,
        enable_upgrade_ticket: this.values.terms.enable_upgrade_ticket,
        enable_exchange_ticket: this.values.terms.enable_exchange_ticket,
        enable_refund_ticket: this.values.terms.enable_refund_ticket,
        enable_transfer_ticket: this.values.terms.enable_transfer_ticket,
        enable_refund_ticket_insurance: this.values.terms
          .enable_refund_ticket_insurance
      };

      // send as FormData to properly handle image uploads
      const data = new FormData();
      let subdomain = this.activeBrand.subdomain;

      data.append("data", JSON.stringify(objToSend));
      data.append("hero", this.values.images.images.heroFile);
      data.append("subdomain", subdomain);

      for (let i = 0; i < this.values.images.images.smallFiles.length; i++) {
        data.append(`small-${i}`, this.values.images.images.smallFiles[i]);
      }

      if (this.values.venue.image) {
        data.append("venueImage", this.values.venue.image.imageFile);
      }

      for (let i = 0; i < this.values.tiers.addedTiers.length; i++) {
        if (this.values.tiers.addedTiers[i].image) {
          data.append(
            `tier-${i}`,
            this.values.tiers.addedTiers[i].image.imageFile
          );
        }
      }

      for (let i = 0; i < this.values.products.products.length; i++) {
        if (this.values.products.products[i].isNew) {
          data.append(
            `products-${i}`,
            this.values.products.products[i].image.imageFile
          );
        }
      }
      console.log(data, objToSend);

      this.$axios
        .post("/events/create", data)
        .then(response => {
          const event = response.data.event;

          if (response.status === 200) {
            this.$store.commit("updateUserProperty", {
              property: "plannerEvents",
              value: 1
            });

            setTimeout(async () => {
              const domainUrl = await whiteLabelRedirection(
                process.env.VUE_APP_ENVIRONMENT,
                this.user,
                event.url
              );
              ///redirect to reserv seats
              if (
                event.eventType == "RESERVED_SEATS" &&
                this.values.brand.useReservedSeats
              ) {
                this.$router.push(`/reserved-seats/` + event.url);
              } else {
                window.open(domainUrl, "_blank", "noreferrer");
                this.$router.push(`/dashboard`);
              }
              this.$store.state.creating = false;
            }, 5000);
          }
          // this.$store.state.creating = false;
        })
        .catch(error => {
          this.$store.state.creating = false;
          console.log(error);
        });
    }
  },
  created() {
    let subdomain = this.$store.state.activeBrand.subdomain;
    this.$store.commit("setTitle", "Create Event");
    this.$axios
      .post("/events/create/users", { subdomain: subdomain })
      .then(response => {
        const data = response.data;
        this.users = data.users;
        if (data.users.length > 0) {
          this.user = data.users[0].id;
        }
      });
  },
  async mounted() {
    this.$store.dispatch(
      "updateHelpVideoSrc",
      "https://youtube.com/embed/ad3Di7fbv9c"
    );
    // Doing this should in theory not allow people to navigate past where they are supposed to be
    if (this.currentStep.link !== "config") {
      await this.$router.replace("/create-event/config");
      this.steps.forEach(s => {
        if (s.number > 1) {
          s.state = "disabled";
        }
      });
    }

    this.$axios
      .post("/company/validate-coordinator", {
        email: this.userEmail
      })
      .then(response => {
        let user;
        if (response.status == 200) {
          user = response.data;
          // gotta make sure redirect modal works
          if (!user.coordinator && !user.sucker) {
            this.$parent.redirectModalMsg =
              "Please complete all your brand settings to create events";
            this.$parent.redirectModalLink = "/brand-settings";
            this.$parent.redirectModal = true;
          }
        }
      });

    this.$axios
      .post("/company/get-info", {
        type: "companyInfo",
        url: this.$store.state.activeBrand.subdomain
      })
      .then(({ data }) => {
        this.values.brand = data;
      });

    this.$axios
      .get(`/brand/${this.$store.state.activeBrand.subdomain}/taxes`)
      .then(response => {
        const data = response.data;
        for (const tax of data.taxes) {
          if (tax.active) {
            this.values.config.taxes.push({
              type: tax.type,
              applicableTo: tax.applicableTo,
              percentage: tax.percentage,
              description: tax.description
            });
          }
        }
      });
  },
  beforeDestroy() {
    this.$store.dispatch("updateHelpVideoSrc", null);
  }
};
</script>

<style lang="less">
.email-dropdown {
  .dropdown {
    font-size: 12px;
    padding: 10px 12px !important;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 90%;
      display: inline-block;
    }
  }
}
</style>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#Content {
  // transition: height 0.5s;

  .content-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 200px;
    padding-top: 80px;

    .page {
      display: flex;
      width: 100%;
      flex-direction: column;

      .email-dropdown {
        .dropdown {
          font-size: 14px;
          padding: 10px 12px;

          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 90%;
          }
        }
      }

      &::v-deep {
        .progress-counter {
          margin: 100px auto 0.5em;
          max-width: 425px;

          p {
            margin: 0;
            font-size: 1.1em !important;
            font-weight: 500;
            text-align: center;
            word-spacing: 1.5px;
            text-transform: uppercase;
            color: #999999;
          }
        }

        .card h2,
        .card h3 {
          margin-top: 0;
        }

        .card .divider {
          margin: 24px 0;
        }
      }

      .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        gap: 16px;

        .formulate-input {
          display: flex;
          width: unset;
        }
      }
    }
  }

  @media screen and (max-width: 1015px) {
    .content-inner {
      flex-wrap: wrap;
    }
  }
}
</style>
